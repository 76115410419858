/*!
 * CLARIN specific fonts
 *
 * Generated with https://google-webfonts-helper.herokuapp.com/fonts/source-code-pro?subsets=latin,latin-ext
 */

/* source-sans-pro-regular - vietnamese_latin-ext_latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
    url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-italic - vietnamese_latin-ext_latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
    src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
    url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-700 - vietnamese_latin-ext_latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
    url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-700italic - vietnamese_latin-ext_latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
    src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'),
    url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/source-sans-pro-v11-vietnamese_latin-ext_latin-700italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
 

/* roboto-slab-regular - vietnamese_cyrillic_latin-ext_greek-ext_cyrillic-ext_latin_greek */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-slab-v7-vietnamese_cyrillic_latin-ext_greek-ext_cyrillic-ext_latin_greek-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto Slab Regular'), local('RobotoSlab-Regular'),
       url('../fonts/roboto-slab-v7-vietnamese_cyrillic_latin-ext_greek-ext_cyrillic-ext_latin_greek-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-slab-v7-vietnamese_cyrillic_latin-ext_greek-ext_cyrillic-ext_latin_greek-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-slab-v7-vietnamese_cyrillic_latin-ext_greek-ext_cyrillic-ext_latin_greek-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-slab-v7-vietnamese_cyrillic_latin-ext_greek-ext_cyrillic-ext_latin_greek-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-slab-v7-vietnamese_cyrillic_latin-ext_greek-ext_cyrillic-ext_latin_greek-regular.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}
/* roboto-slab-700 - vietnamese_cyrillic_latin-ext_greek-ext_cyrillic-ext_latin_greek */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto-slab-v7-vietnamese_cyrillic_latin-ext_greek-ext_cyrillic-ext_latin_greek-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Slab Bold'), local('RobotoSlab-Bold'),
       url('../fonts/roboto-slab-v7-vietnamese_cyrillic_latin-ext_greek-ext_cyrillic-ext_latin_greek-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-slab-v7-vietnamese_cyrillic_latin-ext_greek-ext_cyrillic-ext_latin_greek-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-slab-v7-vietnamese_cyrillic_latin-ext_greek-ext_cyrillic-ext_latin_greek-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-slab-v7-vietnamese_cyrillic_latin-ext_greek-ext_cyrillic-ext_latin_greek-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-slab-v7-vietnamese_cyrillic_latin-ext_greek-ext_cyrillic-ext_latin_greek-700.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}

/* source-code-pro-regular - latin-ext_latin */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/source-code-pro-v7-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Source Code Pro'), local('SourceCodePro-Regular'),
       url('../fonts/source-code-pro-v7-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-code-pro-v7-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-code-pro-v7-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-code-pro-v7-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-code-pro-v7-latin-ext_latin-regular.svg#SourceCodePro') format('svg'); /* Legacy iOS */
}
/* source-code-pro-700 - latin-ext_latin */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/source-code-pro-v7-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local('Source Code Pro Bold'), local('SourceCodePro-Bold'),
       url('../fonts/source-code-pro-v7-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-code-pro-v7-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-code-pro-v7-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-code-pro-v7-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-code-pro-v7-latin-ext_latin-700.svg#SourceCodePro') format('svg'); /* Legacy iOS */
}
