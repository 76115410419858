/*!
 * CLARIN specific classes
 */
 
//////////////////////////////////////////////////////////////////////////////////////////
// We use this file to define additional common classes for CLARIN applications and
// web pages that are NOT part of the default set of bootstrap classes.
//
// You can assume the presence of all standard (CLARIN flavoured) Bootstrap variables 
// and mixins.
//
// This file is combined with the Bootstrap sources using the wrapper LESS file
// 'clarin-bootstrap.less'
//////////////////////////////////////////////////////////////////////////////////////////

// Custom classes

//example:
// .my-clarin-test-class {
// 	 font-size: 333px;
// 	 color: @clarin-color-1;
//	 width: @grid-gutter-width;
// }

.icon-facebook:hover{
	color: #3b5998;
}
.icon-twitter:hover{
	color: #00aced;
}
.icon-gplus:hover{
	color: #dd4b39;
}
.icon-linkedin:hover{
	color: #4875B4;
}
.icon-youtube:hover{
	color: #FF3333;
}
.icon-github:hover{
	color: #000000;
}

